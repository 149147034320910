<template>
  <app-main>
  
       <div id="result" v-loading="loading" name="result" class="text-center mb-5">
          <h4>Thank you for your entry and choosing Red Rock Deli!</h4>
          <p>The draw will take place at PMG, 136 Cathedral St, Woolloomooloo NSW 2011, Australia at 12:00 pm AEST on 20/04/22.</p>
        </div>
  
  </app-main>
</template>
<script>
import { mapGetters } from 'vuex'
import { getWinner } from '@/api/winner.js'


export default {
    name:'WinnerResult',
    components: {
    },
    data() {
        return {
            isWinner: false,
            winnerId: null,
            prize: {},
            loading: true,
        }
    },
    computed: {
        ...mapGetters(['winner'])
    },
    created() {
        this.winnerId = this.$route.query.w
        if (this.winnerId) {
            getWinner(this.winnerId)
                .then(response => {
                    if (response && response.prize) {
                        this.prize = response.prize
                        this.isWinner = true
                    }
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        } else {
            this.loading = false
        }
    },
    methods: {
       
    }
}
</script>

<style lang="scss"></style>
